<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-card outlined flat>
      <v-card-text class="mt-5 mb-3 px-12">
        <app-form :fields="fields" :submit-button="submitButton"
         @canceled="resetIntegration" footerClass="col-12 text-center mt-5" @submitted="handleSubmit" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
export default {
  name: 'Integrations',
  data() {
    return {
      title: {
        text: this.$t('layout.mainMenu.integrations'),
        icon: 'mdi-briefcase-edit'
      },
      breadcrumbs: [{
        text: this.$t('layout.mainMenu.home'),
        to: {name: 'Home'}
      },
      {
        text: this.$t('layout.mainMenu.settings'),
        to: {name: 'Integrations'},
        exact: true
      },
      {
        text: this.$t('layout.mainMenu.integrations'),
        to: {name: 'Integrations'},
        exact: true
      }],
      fields: {
        jiraDomain: {
          type: 'text',
          key: 'jiraURL',
          value: '',
          label: this.$t('models.company.attributes.jiraURL'),
          rules: [{
            name: 'required'
          }],
          classes: 'col-12 py-0'
        },
        jiraUsername: {
          type: 'text',
          key: 'jiraUsername',
          value: '',
          label: this.$t('models.company.attributes.jiraUsername'),
          rules: [{
            name: 'required'
          }],
          classes: 'col-6 py-0'
        },
        jiraPassword: {
          type: 'password',
          key: 'jiraPassword',
          value: '',
          label: this.$t('models.company.attributes.jiraPassword'),
          rules: [{
            name: 'required'
          }],
          classes: 'col-6 py-0'
        }
      },
      submitButton: {
        label: this.$t('actions.save')
      }
    };
  },
  computed: mapGetters(['company']),
  methods: {
    ...mapActions(['getCompany', 'integrateJira']),
    handleSubmit(data) {
      if (data.jira_username && data.jira_password) {
        data.jira_token = btoa(`${data.jira_username}:${data.jira_password}`);
      }
      const form = {
        companyId: this.company.id,
        form: data
      };
      this.integrateJira(form);
    },
    resetIntegration() {
      this.handleSubmit({});
    }
  },
  created() {
    this.getCompany().then(() => {
      this.fields.jiraDomain.value = this.company.jiraURL ? this.company.jiraURL : '';
    });
  }
};
</script>
